import React from 'react'
import Hero from './Hero'

const BecomeMember = ({ padding = 5 }) => {
    return (
        <Hero src="app/SIDC-Membersip.jpg" size={12} height={650} x={30} y={10} dim={0.5} autoAlign={false}>
            <div className="column end">
                <h1 className="text-white text-center"><b>BE AN ACTIVE MEMBER NOW</b></h1>
                <a className="btn btn-primary" href={process.env.GATSBY_SITE_MAIN + '/membership'}>Learn More</a>
            </div>
        </Hero>
    )
}

export default BecomeMember